import type {
  Denormalized,
  IncludedData,
  JsonApiResponse,
  Logger,
  Relationship,
  UnpackRelationships,
} from "./types";

function unpackRelationships<
  Refs extends Record<string, Relationship | undefined> | undefined,
  U extends IncludedData[],
>(relationships: Refs, included?: U, logger: Logger = console): UnpackRelationships<Refs, U> {
  if (relationships === undefined) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return;
  }

  // eslint-disable-next-line consistent-return
  return Object.entries(relationships).reduce(
    (previousValue, currentValue) => {
      const relName = currentValue[0];
      const relValue = currentValue[1];
      if (!relValue) {
        return previousValue;
      }
      const relRefs = relValue.data;

      let unpackedEntities;

      if (relRefs === null) {
        unpackedEntities = null;
      } else if (Array.isArray(relRefs)) {
        unpackedEntities = relRefs.map((ref) => {
          const found = included?.find((item) => item.id === ref.id && item.type === ref.type);

          if (found === undefined) {
            logger.warn("Included data not found for", {
              relName,
              ref,
            });
            return ref;
          }
          const relationships = unpackRelationships(found.relationships, included);
          if (relationships) {
            return {
              ...found,
              relationships,
            };
          } else {
            return found;
          }
        });
      } else {
        const found = included?.find(
          (item) => item.id === relRefs.id && item.type === relRefs.type,
        );
        if (found === undefined) {
          unpackedEntities = relRefs;
          logger.warn("Included data not found for", {
            relName,
            ref: relRefs,
          });
        } else {
          const relationships = unpackRelationships(found.relationships, included);
          if (relationships) {
            unpackedEntities = {
              ...found,
              relationships,
            };
          } else {
            unpackedEntities = found;
          }
        }
      }

      return {
        ...previousValue,
        [relName]: unpackedEntities,
      };
    },
    {} as unknown as UnpackRelationships<Refs, U>,
  );
}

/**
 * Strongly typed denormalization function for JSON:API responses
 * Unpacks relationships and included data
 */
export function denormalize<T extends JsonApiResponse>(
  input: T,
  logger: Logger = console,
): {
  data: Denormalized<T>;
  meta?: T["meta"];
} {
  const { data } = input;
  if (data === null) {
    return input.meta === undefined
      ? {
          data: null as Denormalized<T>,
        }
      : {
          data: null as Denormalized<T>,
          meta: input.meta,
        };
  }
  if (Array.isArray(data)) {
    const resultData = data.map((item) => {
      return {
        attributes: item.attributes,
        id: item.id,
        relationships: unpackRelationships(item.relationships, input.included, logger),
        type: item.type,
      };
    }) as Denormalized<T>;
    return input.meta === undefined
      ? {
          data: resultData,
        }
      : {
          data: resultData,
          meta: input.meta,
        };
  }

  const resultData = {
    attributes: data.attributes,
    id: data.id,
    relationships: unpackRelationships(data.relationships, input.included, logger),
    type: data.type,
  } as Denormalized<T>;
  return input.meta === undefined
    ? {
        data: resultData,
      }
    : {
        data: resultData,
        meta: input.meta,
      };
}

/**
 * Denormalizes JSON:API response if `included` is present, otherwise returns `input` as is.
 * Does not infer the type of the input, so it's not type safe (consider using `io-ts` on the result to make it type safe)
 */
export function denormalizeUnknown(input: unknown): unknown {
  if (typeof input === "object" && input !== null && "data" in input && "included" in input) {
    return denormalize(input as any);
  } else {
    return input;
  }
}
