import { defineStore } from "pinia";
import { SessionC } from "@/network/codecs";
import { denormalizeUnknown } from "@/network/denormalize";
import type { Infer } from "superstruct";
import JSONApiException from "@/network/JSONApiException";
import { setUser } from "@sentry/vue";
import { syncSessionWithRNSecured } from "@/utils/react-native";
import type { Locale } from "@/i18n/shared-types";

export type Session = Infer<typeof SessionC>;

export const useAuthSessionStore = defineStore("authSession", {
  state: () => ({
    rawData: null as unknown,
    meta: null as unknown,
    /**
     * Whether the store has been initialized, so we do not fetch the data again and again
     */
    initialized: false,
  }),
  getters: {
    /**
     * Regular way to get the session.
     */
    session: (state): null | Session => {
      if (state.rawData === null) {
        return null;
      }

      return SessionC.mask(state.rawData);
    },

    isAuthenticated(): boolean | undefined {
      return this.initialized ? !!this.session : undefined;
    },

    availableLanguages(): Locale[] {
      if (!this.session?.relationships.company?.attributes.languages) {
        return [];
      }

      return this.session.relationships.company.attributes.languages;
    },

    /**
     * Whether the user has notifications enabled.
     * If not - we should not show the notifications in the UI.
     */
    notificationsEnabled(): boolean {
      return !!this.session?.attributes.notifications_enabled;
    },

    csrfToken: (state): string | null => {
      return typeof state.meta === "object" &&
        state.meta !== null &&
        "csrf_token" in state.meta &&
        typeof state.meta.csrf_token === "string"
        ? state.meta.csrf_token
        : null;
    },

    hasCobra: (state): boolean | null => {
      return typeof state.meta === "object" &&
        state.meta !== null &&
        "cobra" in state.meta &&
        typeof state.meta.cobra === "boolean"
        ? state.meta.cobra
        : null;
    },

    /**
     * The app should not be used if the user has not accepted the data sharing consent.
     */
    hasToGiveDataSharingConsent(): boolean {
      return !!this.session?.attributes.require_data_sharing_consent;
    },
  },
  actions: {
    async fetchData(options?: { revalidate: boolean }) {
      if (this.initialized && !options?.revalidate) {
        return;
      }
      try {
        const response = (await this.apiClient
          .get("v3/session")
          .json()
          .then(denormalizeUnknown)) as {
          data: unknown;
          meta: unknown;
        };
        this.rawData = response.data;
        this.meta = response.meta;
        const userId = this.session?.id;
        if (userId) {
          setUser({
            id: userId,
          });
        } else {
          setUser(null);
        }
        await this.applyBackendLocale();
        syncSessionWithRNSecured(this.session);
      } catch (e) {
        if (e instanceof JSONApiException) {
          const response = (await e.response.clone().json()) as {
            meta: unknown;
          };
          this.meta = response.meta;
        } else {
          throw e;
        }
      } finally {
        this.initialized = true;
      }
    },
    /**
     * Some actions might need to update the session data.
     * E.g. when the user sign-in or sign-up.
     *
     * @param data - denormalized JSON:API response compatible with the `data` from the GET /v2/session endpoint
     * @param meta - metadata from the response
     */
    async updateSession(data: unknown, meta: unknown) {
      this.rawData = data;
      this.meta = meta;
      this.initialized = true;
      await this.applyBackendLocale();

      syncSessionWithRNSecured(this.session);
    },
    /**
     * Locale need to be in sync with the backend.
     */
    async applyBackendLocale() {
      const userLocale = this.session?.attributes.preferred_language;
      const appLocale = this.translator.currentLocale;

      if (userLocale && userLocale !== appLocale) {
        console.debug(
          "App locale is different from the backend locale",
          {
            userLocale,
            appLocale,
          },
          "Reloading the page to apply the backend locale",
        );

        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.set("locale", userLocale);
        // window.location.search = urlSearchParams.toString(); // FIXME: https://storkclub.atlassian.net/browse/STRK-2665
      }
    },
    async logout() {
      try {
        await this.apiClient.delete("v3/session");
        window.location.reload(); // temporary solution as we need to refresh CSRF token. In the future we would like to receive new CSRF token from the server
      } catch (e) {
        console.error("Failed to logout", e);
        window.location.reload();
      }
    },
    updateDataSharing(requireDataSharingConsent: boolean) {
      interface RawData {
        attributes?: {
          require_data_sharing_consent: boolean;
        };
      }

      if (this.rawData && (this.rawData as RawData).attributes) {
        (this.rawData as RawData).attributes!.require_data_sharing_consent =
          requireDataSharingConsent;
      }
    },
  },
});
