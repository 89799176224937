import type { PostHogService } from "@/posthog/types";
import posthog, { type CaptureOptions, type PostHog, type Properties } from "posthog-js";

export default class RealPostHog implements PostHogService {
  private readonly posthog: PostHog | undefined;

  public constructor(token: string) {
    console.debug("Initializing real PostHog");
    this.posthog = posthog.init(token, {
      api_host: "https://us.i.posthog.com",
      opt_out_capturing_by_default: true, // We opt out by default and only enable if the user has given consent using useWatchPostHogConsent

      // This is SPA, we capture them on the router level and don't want to capture them twice
      capture_pageview: false,
      capture_pageleave: false,
      session_recording: {
        maskAllInputs: true,
      }
    });
  }

  public reset() {
    this.posthog?.reset();
  }

  public startSessionRecording() {
    this.posthog?.startSessionRecording();
  }

  public stopSessionRecording() {
    this.posthog?.stopSessionRecording();
  }

  public identify(
    new_distinct_id?: string,
    userPropertiesToSet?: Properties,
    userPropertiesToSetOnce?: Properties,
  ) {
    this.posthog?.identify(new_distinct_id, userPropertiesToSet, userPropertiesToSetOnce);
  }

  public capture(event_name: string, properties?: Properties | null, options?: CaptureOptions) {
    return this.posthog?.capture(event_name, properties, options);
  }

  public opt_out_capturing() {
    this.posthog?.opt_out_capturing();
  }

  public opt_in_capturing() {
    this.posthog?.opt_in_capturing();
  }

  public has_opted_in_capturing() {
    return this.posthog?.has_opted_in_capturing() || false;
  }

  public get_distinct_id() {
    return this.posthog?.get_distinct_id();
  }
}
