import "./assets/css/globals.css";
import router, { routerShowErrorPage } from "./router";
import { API_CLIENT, createApiClient } from "@/network/apiClient";
import { createPinia } from "pinia";
import { i18nPlugin, initializeLocale } from "@/i18n";
import PortalVuePlugin from "portal-vue";
import * as Sentry from "@sentry/vue";
import { storeInitialLocation } from "@/router/preserve-location";
import { app } from "@/app";
import { ref } from "vue";
import "@/utils/viewport";
import { useTranslator } from "@/composables/useTranslator";
import posthogPlugin from "@/posthog";
import type { PostHogService } from "@/posthog/types";

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    enabled: !!import.meta.env.SENTRY_AUTH_TOKEN,
    app,
    environment: import.meta.env.VITE_SENTRY_ENV || "unknown",
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration({ router })],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/product\.aws\.joinstorkclub\.com\/api/,
      /^https:\/\/my\.joinstorkclub\.com\/api/,
    ],
  });
}

app.use(posthogPlugin);
const pinia = createPinia();
app.use(pinia);

declare module "pinia" {
  export interface PiniaCustomProperties {
    get apiClient(): typeof apiClient;
    get translator(): typeof translator;
    get posthog(): PostHogService;
  }
}

app.use(i18nPlugin);

app.directive("preserve-aspect-ratio", (el) => {
  const iframe = el.querySelector("iframe");
  const width = iframe?.getAttribute("width");
  const height = iframe?.getAttribute("height");
  if (!iframe || !width || !height) return;

  iframe.style.aspectRatio = `${width} / ${height}`;
});

const translator = app.runWithContext(() => {
  return useTranslator();
});

pinia.use((context) => {
  const posthog = context.app.config.globalProperties.$posthog;
  return {
    // There is circular dependency between apiClient and pinia, so we need to create another instance of apiClient here
    apiClient: createApiClient(context.pinia, translator),
    translator,
    posthog: posthog
  };
});

const apiClient = createApiClient(pinia, translator);
app.provide(API_CLIENT, apiClient);

app.use(router);
app.use({
  install: (app) => {
    app.provide(routerShowErrorPage, ref(false));
  },
});


app.use(PortalVuePlugin);

initializeLocale().then((locale) => {
  storeInitialLocation();
  document.documentElement.lang = locale;
  app.mount("#app");
});

app.config.errorHandler = (err, vm, info) => {
  Sentry.captureException(err, {
    extra: {
      info,
    },
  });
};
